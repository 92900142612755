var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};
var isCr21 = Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr21_mpp;

(function ($) {
  var rtl = $('html').attr('dir') === 'rtl';
  var left = rtl ? 'right' : 'left';
  var right = rtl ? 'left' : 'right';

  $(document).on('product.updateInvStatus', '.js-product-ui', function () {
    var $product = $(this);
    var $swatchLists = $('.js-product-brief-shades__grid', $product);

    $swatchLists.each(function () {
      var $swatchList = $(this);
      var $swatches = $('.js-product-brief-shades-item-link', $swatchList);

      $swatches.once('inv-status-updated').each(function () {
        var $swatchLink = $(this);
        var skuBaseId = $swatchLink.data('skuBaseId');
        var skuData = prodcat.data.getSku(skuBaseId);

        Drupal.behaviors.productBriefShadesV1.inventoryCheck($swatchLink, skuData);
      });
    });
  });

  $(document).on('shade-selectFirst', '.js-product-grid', function (e, badge) {
    var $swatchLists = $('.js-product-brief-shades__grid', $(this));
    $swatchLists.each(function () {
      var $swatchList = $(this);

      Drupal.behaviors.productBriefShadesV1.selectFirstSkuByBadge($swatchList, badge);
    });
  });

  $(document).on(
    'grid.shadePickerFilters',
    '.js-filterable-products__grid',
    function (e, activeFilters) {
      var $grid = $(this);

      if ($grid.hasClass('reseting-filters')) {
        return;
      }
      Drupal.behaviors.productBriefShadesV1.resetFilters($grid);
      var $swatchLists = $(
        '.js-filterable-products__grid-item .js-product-brief-shades-item',
        $grid
      );

      $swatchLists.closest('.js-product-brief-shades__grid').removeClass('reset-dimensions');
      Drupal.behaviors.productBriefShadesV1.rebuildShadesSliderOnActiveFilter(
        $swatchLists,
        activeFilters
      );

      if (!$.isEmptyObject(activeFilters) && activeFilters.filterString !== '') {
        // add the reset flag for visible sliders due to product level filter matching
        var $products = $('.js-filterable-products__grid-item' + activeFilters.filterString, $grid);

        $('.js-product-brief-shades__grid:not(.reset-dimensions)', $products).addClass(
          'reset-dimensions'
        );
      }
    }
  );

  $(document).on('arrangeComplete', '.js-filterable-products__grid', function () {
    var $grids = $(this);

    $grids.each(function () {
      var $grid = $(this);

      Drupal.behaviors.productBriefShadesV1.productGridRefresh($grid);
      // if resetFilter was triggered, the slick dimensions need to be recalled on grid animation complete
      Drupal.behaviors.productBriefShadesV1.productGridSetDimensions($grid);
    });
  });

  $(document).on('mixEnd', '.js-filterable-products__grid', function () {
    Drupal.behaviors.productBriefShadesV1.productGridRefresh($(this));
  });

  $(document).on('grid.reset.filters', '.js-mpp-custom-override-layout', function () {
    var $grids = $('.js-filterable-products__grid', $(this));

    $grids.each(function () {
      var $grid = $(this);

      if ($grid.hasClass('reseting-filters')) {
        // if the grid is in reseting state, the resetFilters will get trigger on animation done
        return;
      }
      Drupal.behaviors.productBriefShadesV1.resetFilters($grid);
    });
  });

  $(document).on('product.brief-loaded', '.js-product-brief-shades-item-link', function () {
    var $shades = $(this);

    Drupal.behaviors.productBriefShadesV1.processShadeSelection($shades);
  });

  Drupal.behaviors.productBriefShadesV1 = {
    isMobile: false,
    isMedium: false,
    selector: {
      shadesGrid: '.js-product-brief-shades__grid'
    },
    attach: function (context) {
      var self = this;

      self.isMobile = self.checkIfMobile();
      self.isMedium = self.checkIfMedium();
      var $shadesGrid = $(self.selector.shadesGrid, context);

      Unison.on('change', function () {
        var isDesktop = !self.isMobile && !self.isMedium;

        self.isMobile = self.checkIfMobile();
        self.isMedium = self.checkIfMedium();
        var sameLayout = isDesktop === !self.isMobile && !self.isMedium;

        if (!sameLayout) {
          self.swapLayout($shadesGrid);
        }
        self.init($shadesGrid);
        self.setEvents(context, $shadesGrid);
      });
      self.init($shadesGrid);
      self.setEvents(context, $shadesGrid);
      if (Drupal.settings.globals_variables.cr21_mpp) {
        $(document).on('productBriefShades.init', () => {
          self.init($(self.selector.shadesGrid, context));
        });
      }
    },

    productGridSetDimensions: function ($grid) {
      $sliders = $('.js-product-brief-shades__grid', $grid).filter('.reset-dimensions');
      $newSliders = $('.js-product-brief-shades__grid', $grid).filter(':visible').not($sliders);
      $sliders = $sliders.add($newSliders);
      if ($sliders.length > 0) {
        $sliders.each(function () {
          $(this).removeClass('reset-dimensions');
        });
      }
    },

    productGridRefresh: function ($grid) {
      var self = this;

      if ($grid.hasClass('reseting-filters')) {
        $grid.removeClass('reseting-filters');
        self.resetFilters($grid);

        return;
      }
      $('.js-filter-swatch-slider:not(.slick-initialized)', $grid).each(function () {
        // we initialize the filter carousels only when the item is fully visible else
        // the sliders doesn't load right due to item being hidden
        var $swatchList = $(this);

        $swatchList.removeClass('disable-arrow');
        self.initializeSlick($(this));
      });

      var $initializedFilteredGrids = $('.js-filter-swatch-slider', $grid);
      var $visibleShadeGrids = $('.js-product-brief-shades__grid', $grid);

      if ($initializedFilteredGrids.length > 0 || $visibleShadeGrids.length > 0) {
        // on filter we only need to setDimensions on the shade sliders
        self.productGridSetDimensions($grid);
      }
    },

    resetFilters: function ($grid) {
      var self = this;

      // remove all the filter sliders on filter reset
      $('.js-filter-swatch-slider', $grid).remove();
      $('.js-product-brief-shades__grid:not(.js-filter-swatch-slider)', $grid).each(function () {
        var $slider = $(this).removeClass('disable-arrow');

        self.setSlickDimensions($slider);
      });
    },

    setSlickDimensions: function ($slider) {
      var self = this;
      if ($slider.hasClass('hidden')) {
        return;
      }
      var sliderInitialized = true;
      var $swatches = $('.js-product-brief-shades-item-link', $slider);
      var shadeCount = $swatches.length;
      var isComparison = $slider.closest('.js-comparison--item').length > 0;
      var defaultSlidesToShow = self.isMobile && isComparison ? 3 : 8;
      var slidesToShowData = $slider.data('slidesToShow') || defaultSlidesToShow;
      var showArrows = shadeCount > slidesToShowData ? true : false;
      var showArrowLeft = $slider.scrollLeft() <= 0;
      var shadesGridWidthCalculated;

      if (self.isMobile) {
        shadesGridWidthCalculated =
          Math.floor($slider[0].scrollWidth) - Math.floor($slider.scrollLeft()) - 1;
      } else {
        shadesGridWidthCalculated =
          Math.floor($slider[0].scrollWidth) - Math.floor($slider.scrollLeft());
      }
      var showArrowRight = shadesGridWidthCalculated <= Math.floor($slider.outerWidth(true));
      var $prevArrow = $slider.parent().find('.js-brief-product-arrow--' + left);
      var $nextArrow = $slider.parent().find('.js-brief-product-arrow--' + right);

      self.toggleSliderArrows(
        $prevArrow,
        $nextArrow,
        showArrows,
        sliderInitialized,
        showArrowLeft,
        showArrowRight
      );
    },

    swapLayout: function ($shadesGrid) {
      var self = this;
      var $sliders = $shadesGrid.filter('.inital-slide-processed');

      if ($sliders < 1) {
        // if the grid was not initialized or doesn't have more slides then it shows
        // we don't need to change anything at it
        return;
      }

      $sliders.each(function () {
        var $slider = $(this);

        // need to reset the arrows as slick removes them on destroy, and we need the html to stay in place
        $slider[0].slick.$prevArrow = $();
        $slider[0].slick.$nextArrow = $();

        $slider.unslick();
        $slider.off('init-slick');
        $slider[0].slick = undefined;
        $slider.removeClass('inital-slide-processed');
        self.initializeSlick($slider);
      });
    },

    checkIfMobile: function () {
      if (!!Unison.fetch.now()) {
        var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
        var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

        return currentBpWidth <= bPlargeUp;
      }

      return false;
    },

    checkIfMedium: function () {
      if (!!Unison.fetch.now()) {
        var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
        var bPlargeUp = parseInt(Unison.fetch.all()['usn-large'], 10);

        return currentBpWidth <= bPlargeUp;
      }

      return false;
    },
    manageArrows: function ($that, direction) {
      var self = this;
      var $swatchList = $that.parent().closest('.js-product-brief-shades');
      var $shadesGrid = $('.js-product-brief-shades__grid:not(.hidden)', $swatchList);
      var cr21SwatchWidth = self.isMobile ? 38 : 30;
      var shadeSwatchWidth = isCr21
        ? cr21SwatchWidth
        : $('.js-product-brief-shades-item', $shadesGrid).first().width();
      var $prevArrow = $shadesGrid.parent().find('.js-brief-product-arrow--' + left);
      var $nextArrow = $shadesGrid.parent().find('.js-brief-product-arrow--' + right);

      // Prim check.
      direction === 'left' ? $nextArrow.removeClass('disable-arrow') : $prevArrow.removeClass('disable-arrow');

      $shadesGrid.animate(
        {
          scrollLeft:
            (direction === 'left' ? (rtl ? '+=' : '-=') : rtl ? '-=' : '+=') + shadeSwatchWidth
        },
        'normal',
        function () {
          var shadesGridWidthCalculated;
          if (direction === 'left') {
            if (rtl ? $shadesGrid.scrollLeft() >= 0 : $shadesGrid.scrollLeft() <= 0) {
              $prevArrow.addClass('disable-arrow');
            }
          }
          if (direction === 'right') {
            shadesGridWidthCalculated = self.isMobile
              ? rtl
                ? Math.floor($shadesGrid[0].scrollWidth) + Math.floor($shadesGrid.scrollLeft()) - 1
                : Math.floor($shadesGrid[0].scrollWidth) - Math.floor($shadesGrid.scrollLeft()) - 1
              : rtl
              ? Math.floor($shadesGrid[0].scrollWidth) + Math.floor($shadesGrid.scrollLeft())
              : Math.floor($shadesGrid[0].scrollWidth) - Math.floor($shadesGrid.scrollLeft());
            shadesGridWidthCalculated <= Math.floor($shadesGrid.outerWidth(true))
              ? $nextArrow.addClass('disable-arrow')
              : null;
          }
        }
      );
    },
    setEvents: function (context, $shadesGrid) {
      var self = this;

      $(document)
        .off('click.productBriefShadesArrowLeft')
        .on('click.productBriefShadesArrowLeft', '.js-brief-product-arrow--' + left, function () {
          var $this = $(this);
          self.manageArrows($this, 'left');
        });

      $(document)
        .off('click.productBriefShadesArrowRight')
        .on('click.productBriefShadesArrowRight', '.js-brief-product-arrow--' + right, function () {
          var $this = $(this);
          self.manageArrows($this, 'right');
        });
      // only bind the visible items, the hidden will get rebound on parent show / load
      $(document)
        .off('click.productBriefShadesVisible')
        .on(
          'click.productBriefShadesVisible',
          '.js-product-brief-shades-item-link:visible',
          function (e) {
            e.preventDefault();
            var $this = $(this);

            self.processShadeSelection($this);
          }
        );
      // load triggers a reset on shades, since they are pure html "sliders"
      // we need to re scroll elelemnts
      $(window).on('load', function () {
        $shadesGrid.each(function () {
          var $shadeList = $(this);
          var reScroll =
            $shadeList.data('selectedSlideIndex') && $shadeList.data('selectedSlideIndex') > 6;
          if (reScroll) {
            setTimeout(function () {
              self.selectFirstSku($shadeList);
            }, 0);
          }
        });
      });
      $(document).on('product.skuSelect', '.js-product-ui', function (e, skuBaseId) {
        var $product = $(this);
        var $swatchList = $product.find($shadesGrid);
        self.selectSku($swatchList, skuBaseId);
      });
    },

    init: function ($swatchLists) {
      var self = this;
      $swatchLists.each(function () {
        var $swatchList = $(this);
        var $swatches = $('.js-product-brief-shades-item-link', $swatchList);

        // add gray border to light shades (lighter than  R: 233 G: 223 B: 245 or hex #E9DFF5)
        $swatches.each(function () {
          var $swatchLink = $(this);

          self.cleanBadgeValue($swatchLink);
          var rgbStr = $swatchLink.css('backgroundColor');
          var matches = rgbStr.match(/(\d*),\s(\d*),\s(\d*)/);

          // IE8 returns a hex value; other browsers return rgb. We're not drawing outlines in IE8 anyway, so return.
          if (!matches) {
            return true;
          }
          var red = parseInt(matches[1]);
          var green = parseInt(matches[2]);
          var blue = parseInt(matches[3]);

          if (red > 233 && green > 223 && blue > 243) {
            $swatchLink.addClass('light-shade');
          }
        });
        self.selectFirstSku($swatchList);
        self.initializeSlick($swatchList);
      });
    },

    processShadeSelection: function ($that) {
      var self = this;
      var shadeSkuBaseId = $that.data('skuBaseId');

      self.selectShade($that, shadeSkuBaseId);
    },

    cleanBadgeValue: function ($swatchLink) {
      if ($swatchLink && typeof $swatchLink.data('miscFlagText') === 'string') {
        var cleanBadge = $swatchLink.data('miscFlagText').replace(/ /g, '_').toLowerCase();

        $swatchLink.data('miscFlagText', cleanBadge).attr('data-misc-flag-text', cleanBadge);
      }
    },

    rebuildShadesSliderOnActiveFilter: function ($swatchList, activeFilters) {
      var self = this;

      if ($swatchList.length < 1) {
        return null;
      }
      // remove all the filter sliders on filter
      $('.js-filter-swatch-slider').remove();
      if (!$.isEmptyObject(activeFilters) && activeFilters.filterString !== '') {
        var $sliders = $swatchList
          .filter(activeFilters.filterString)
          .closest('.js-product-brief-shades__grid');

        if ($sliders.length < 1) {
          return;
        }
        $.each($sliders, function () {
          $(this).addClass('hidden');
          self.initializeFilterSlick(
            $(this),
            $(this).find($swatchList).filter(activeFilters.filterString)
          );
        });
      } else {
        $swatchList.removeClass('disable-arrow');
        $swatchList.closest('.js-product-brief-shades__grid').addClass('reset-dimensions');
      }
    },

    initializeFilterSlick: function ($slider, $slides) {
      var self = this;

      $slides = $slides.clone(true);
      var $sliderFilterClone = $slider.clone(true).addClass('js-filter-swatch-slider');

      // clone the original slider and repopulate only with
      // the shades that match the filter
      // we remove the original slick html
      $sliderFilterClone.find('.js-product-brief-shades-item').remove();
      $slides.css({ width: '' });
      $sliderFilterClone.append($slides);
      $sliderFilterClone.removeClass('hidden');

      $slider
        .parent()
        .find('.js-brief-product-arrow--' + left)
        .addClass('disable-arrow');
      if ($slider.parent().find('.js-filter-swatch-slider').length < 1) {
        // clone doesn't keep the slick from the object so we have to
        // initialize the slider on the newly created slider
        $sliderFilterClone.insertAfter($slider);
        self.selectShade($slides.eq(0));
      }
    },

    initializeSlick: function ($slider) {
      var self = this;
      var sliderInitialized = false;
      var $swatches = $('.js-product-brief-shades-item-link', $slider);
      var $slides = $('.js-product-brief-shades-item', $slider);
      var shadeCount = $swatches.length;
      var isComparison = $slider.closest('.js-comparison--item').length > 0;
      var defaultSlidesToShow = self.isMobile && isComparison ? 3 : 8;
      var slidesToShowData = $slider.data('slidesToShow') || defaultSlidesToShow;
      var $prevArrow = $slider.parent().find('.js-brief-product-arrow--' + left);
      var $nextArrow = $slider.parent().find('.js-brief-product-arrow--' + right);
      var showArrows = shadeCount > slidesToShowData ? true : false;

      if (!showArrows) {
        var slideSize = 100 / parseInt(shadeCount);

        $slides.each(function () {
          $(this).css({ width: slideSize + '%' });
        });
      } else {
        $slider.addClass('is-slider');
      }
      self.toggleSliderArrows($prevArrow, $nextArrow, showArrows, sliderInitialized);
      $slider.attr('data-shade-count', shadeCount);
    },

    showBothSliderArrows: function ($prevArrow, $nextArrow, showArrowLeft, showArrowRight) {
      if (!showArrowLeft && !showArrowRight) {
        $prevArrow.removeClass('disable-arrow');
        $nextArrow.removeClass('disable-arrow');
      }
    },

    toggleSliderArrows: function (
      $prevArrow,
      $nextArrow,
      showArrows,
      sliderInitialized,
      showArrowLeft,
      showArrowRight
    ) {
      var self = this;

      if (sliderInitialized && showArrows) {
        if (showArrowLeft && !showArrowRight) {
          $prevArrow.addClass('disable-arrow');
          $nextArrow.removeClass('disable-arrow');
        } else {
          $prevArrow.removeClass('disable-arrow');
          $nextArrow.addClass('disable-arrow');
        }
        self.showBothSliderArrows($prevArrow, $nextArrow, showArrowLeft, showArrowRight);
      } else {
        if (showArrows) {
          $nextArrow.removeClass('hidden');
        } else {
          $prevArrow.addClass('hidden');
          $nextArrow.addClass('hidden');
        }
      }
    },

    selectShade: function ($swatchLink) {
      var self = this;
      var selectedSkuBaseId = $swatchLink.attr('data-sku-base-id');
      var $productEl = $swatchLink.closest('.js-product-ui');
      var selectedProductId = $productEl.data('productId');
      var $shadeList = $swatchLink.closest('.js-product-brief-shades__grid');
      var triggerSkuSelect = !$shadeList.hasClass('hidden');

      if (triggerSkuSelect) {
        // don't trigger multiple times per brief on page load ( deep link )
        $productEl.data('shadeSelected', true).trigger('product.skuSelect', selectedSkuBaseId);
      }

      self.selectSku($shadeList, selectedSkuBaseId);

      if (triggerSkuSelect) {
        $productEl.trigger('product.productSelect', [selectedProductId, selectedSkuBaseId]);
      }

      if ($swatchLink.hasClass('selected')) {
        $swatchLink.attr({
          'aria-selected': 'true',
          'aria-describedby': 'js-shade-updating-message'
        });
      }
    },

    selectSku: function ($swatchList, skuBaseId) {
      $swatchList.data('sku-base-id', skuBaseId);
      var $links = $('.js-product-brief-shades-item-link', $swatchList);

      $links.removeClass('selected');
      $(
        ".js-product-brief-shades-item-link[data-sku-base-id='" + skuBaseId + "']",
        $swatchList
      ).addClass('selected');
    },

    selectFirstSku: function ($swatchList) {
      var self = this;
      var selectedSkuBaseId = parseInt(
        $swatchList.closest('.js-product-brief-shades').data('skuBaseId')
      );
      var $selectedShadeItem = $(
        ".js-product-brief-shades-item-link[data-sku-base-id='" + selectedSkuBaseId + "']",
        $swatchList
      );
      if ($selectedShadeItem.length < 1) {
        return;
      }

      var positionShadeItem = $selectedShadeItem.position().left;
      if (positionShadeItem >= $swatchList.width()) {
        $swatchList.scrollLeft(positionShadeItem);
      }

      self.setSlickDimensions($swatchList);
      var selectedShadeIndex = $('.js-product-brief-shades-item-link', $swatchList).index(
        $selectedShadeItem
      );

      $selectedShadeItem.addClass('selected');
      $swatchList.data('selectedSlideIndex', selectedShadeIndex);
      self.selectShade($selectedShadeItem);
    },

    selectFirstSkuByBadge: function ($swatchList, badge) {
      var self = this;
      var $selectedShadeItem = $(
        ".js-product-brief-shades-item:not(.slick-cloned) .js-product-brief-shades-item-link[data-misc-flag-text='" +
          badge +
          "']",
        $swatchList
      ).first();

      if ($selectedShadeItem.length < 1) {
        return;
      }
      var selectedShadeIndex = $(
        '.js-product-brief-shades-item:not(.slick-cloned) .js-product-brief-shades-item-link',
        $swatchList
      ).index($selectedShadeItem);

      $selectedShadeItem.addClass('selected');
      $swatchList.data('selectedSlideIndex', selectedShadeIndex);
      self.selectShade($selectedShadeItem);
      $swatchList.slickGoTo(selectedShadeIndex);
    },

    inventoryCheck: function ($swatchLink, skuData) {
      var classPrefix = 'shade-inventory-status--';

      if (!!skuData) {
        var loadedInventoryStatus = parseInt($swatchLink.data('inventoryStatus'));
        var L2InventoryStatus = parseInt(skuData.INVENTORY_STATUS);

        // If the updated L2 is the same status. Nothing to update.
        if (!_.isUndefined(loadedInventoryStatus) && L2InventoryStatus === loadedInventoryStatus) {
          return;
        }
        if (!!loadedInventoryStatus) {
          $swatchLink.removeClass(classPrefix + loadedInventoryStatus);
        }
        $swatchLink
          .addClass(classPrefix + L2InventoryStatus)
          .data('inventoryStatus', L2InventoryStatus)
          .attr('data-inventory-status', L2InventoryStatus);
      }
    }
  };
})(jQuery);
